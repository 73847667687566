import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import Layout from "../../layouts/index"
import SEO from "../../components/seo"
import Background from "../../components/background"
import Image from "../../components/image"
import { color } from "../../utils"

const ProjectsWrapper = styled.div`
  position: relative;
  background-color: ${color.backgroundTransparencyBlack};
  z-index: 2;
  padding: 2rem;
  margin: 0;
  display: grid;
  column-gap: 0;
  row-gap: 0;
  grid-template-columns: 1fr;
  grid-template-areas:
    "title"
    "hkdbPic"
    "hkdbExp"
    "evoDevoPic"
    "evoDevoExp"
    "mcmPic"
    "mcmExp"
    "morePic"
    "moreExp";

  .title {
    grid-area: title;
  }
  .hkdbPic {
    grid-area: hkdbPic;
  }
  .hkdbExp {
    grid-area: hkdbExp;
  }
  .evoDevoPic {
    grid-area: evoDevoPic;
  }
  .evoDevoExp {
    grid-area: evoDevoExp;
  }
  .mcmPic {
    grid-area: mcmPic;
  }
  .mcmExp {
    grid-area: mcmExp;
  }
  .morePic {
    grid-area: morePic;
  }
  .moreExp {
    grid-area: moreExp;
  }

  div {
    background-color: ${color.backgroundTransparencyWhite};
  }

  .pic,
  .exp {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    padding: 20px 1rem;
  }

  .smiley:after {
    content: "  😊";
    font-size: 2rem;
  }

  .overlay:hover .smiley:after {
    content: "  🤘";
    font-size: 2rem;
  }

  .exp span {
    font-weight: bold;
  }

  .img-title {
    position: relative;
  }

  .pic figure {
    display: none;
  }

  .overlay {
    width: 100%;
    height: 150px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${color.backgroundTransparencyBlack};
    border: 4px solid white;

    h2 {
      font-size: 1.8rem;
      font-family: "MuseoModerno", cursive;
      text-align: center;
    }
  }

  .overlay:hover {
    color: transparent;
    background-color: transparent;
    transition: color 1s, background-color 1s;
  }

  @media screen and (min-width: 768px) {
    margin: 2% 8%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto repeat(4, 1fr);
    grid-template-areas:
      "title title title"
      "hkdbPic hkdbPic hkdbExp"
      "evoDevoExp evoDevoPic evoDevoPic"
      "mcmPic mcmPic mcmExp"
      "moreExp morePic morePic";
    padding: 2rem;

    .pic figure {
      width: 100%;
      height: 280px;
      margin: 0;
      overflow: hidden;
      display: block;
    }

    .exp {
      padding: 2rem 1rem;
    }

    .overlay {
      height: 100%;
      position: absolute;
    }

    .overlay h2 {
      font-size: 3rem;
      padding: 25px;

      // mix-blend-mode: difference;
      // color: darksalmon;
    }
  }

  @media screen and (min-width: 1000px) {
    margin: 3% 10%;
  }

  opacity: 0;
  animation: fade 1.5s ease-in-out forwards;
  @keyframes fade {
    to {
      opacity: 1;
    }
  }
`
const Title = styled.h1`
  &.title {
    font-size: 2rem;
    text-align: center;
    margin: 2rem 0;
    color: ${color.backgroundTransparencyWhite};
    font-weight: 900;
  }

  @media screen and (min-width: 768px) {
    &.title {
      font-size: 2.5rem;
    }
  }
`

const Projects = () => {
  return (
    <Layout>
      <SEO title="Projects" />
      <Background opacity="low" />
      <ProjectsWrapper>
        <Title className="title">Projects</Title>
        <div className="pic hkdbPic">
          <Link to="/projects/hkdb/">
            <div className="img-title">
              <Image
                fileName="hkdb-dna-rna-samples.png"
                alt="HKDB sample page"
              />
              <div className="overlay">
                <h2>HKDB</h2>
              </div>
            </div>
          </Link>
        </div>
        <div className="exp hkdbExp">
          <p>
            <span>Type:</span> Web app for database interaction
          </p>
          <p>
            <span>Role:</span> Design and Development (fullstack)
          </p>
          <p>
            <span>Tech stack:</span> Python, Django, MySQL, HTML, CSS,
            JavaScript, Bootstrap, jQuery, GitLab
          </p>
        </div>
        <div className="pic evoDevoPic">
          <Link to="/projects/evodevo/">
            <div className="img-title">
              <Image fileName="evodevo-desktop.png" alt="EvoDevo app" />
              <div className="overlay">
                <h2>Evo Devo</h2>
              </div>
            </div>
          </Link>
        </div>
        <div className="exp evoDevoExp">
          <p>
            <span>Type:</span> Web app for data visualization
          </p>
          <p>
            <span>Role:</span> Design and Development (fullstack)
          </p>
          <p>
            <span>Tech stack:</span> R, R Shiny, CSS, Git
          </p>
        </div>
        <div className="pic mcmPic">
          <Link to="/projects/mcm/">
            <div className="img-title">
              <Image
                fileName="mcm-books.png"
                alt="researcher's website - book recommendations' page"
              />
              <div className="overlay">
                <h2>Researcher's Website</h2>
              </div>
            </div>
          </Link>
        </div>
        <div className="exp mcmExp">
          <p>
            <span>Type:</span> Personal website
          </p>
          <p>
            <span>Role:</span> Design and Development (frontend)
          </p>
          <p>
            <span>Tech stack:</span> HTML, CSS, Javascript, jQuery, GitLab
          </p>
        </div>
        <div className="pic morePic">
          <Link to="/projects/portfolio/">
            <div className="img-title">
              <Image
                fileName="website-screenshot.png"
                alt="This website's homepage"
              />
              <div className="overlay">
                <h2 class="smiley">This Portfolio</h2>
              </div>
            </div>
          </Link>
        </div>
        <div className="exp moreExp">
          <p>
            <span>Type:</span> Portfolio
          </p>
          <p>
            <span>Role:</span> Design and Development (frontend)
          </p>
          <p>
            <span>Tech stack:</span> Gatsby, React, HTML, CSS, Javascript,
            GSAP3, GitLab, Netlify
          </p>
        </div>
      </ProjectsWrapper>
    </Layout>
  )
}

export default Projects
